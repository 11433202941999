import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import MainSectionZakaznickaZona from "../components/zakaznicka-zona/mainSection.js";

import "../styles/programovanie-na-mieru.scss";
import ContactUs from "../components/contactUs";
import ProgramovanieSolutionBox from "../components/programovanie-na-mieru/programovanieSolutionBox";
import ZonaFaqQuestion from "../components/zakaznicka-zona/zonaFaqQuestion.js";
import SliderSectionZona from "../components/zakaznicka-zona/sliderSection.js";
import References from "../components/homepage/references.js";
import ZonaClankySlider from "../components/zakaznicka-zona/zonaClankySlider";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";

import objavenieImg from "../images/web-na-mieru/web-na-mieru-konzultacia-objavenie.jpg";
import dizajnImg from "../images/web-na-mieru/web-na-mieru-dizajn-kreslenie.jpg";
import realizaciaImg from "../images/web-na-mieru/web-na-mieru-realizacia.jpg";
import testovanieImg from "../images/web-na-mieru/web-na-mieru-testovanie.jpg";
import nasadenieImg from "../images/web-na-mieru/web-na-mieru-spustenie-a-podpora.jpg";

import jedinecnostIcon from "../images/web-na-mieru/web-na-mieru-unikatnost.svg";
import flexibilitaIcon from "../images/web-na-mieru/web-na-mieru-flexibilita.svg";
import rastIcon from "../images/web-na-mieru/web-na-mieru-rozsirenia.svg";
import optimalizaciaIcon from "../images/web-na-mieru/web-na-mieru-optimalizacia-pre-vyhladavace.svg";
import integraciaIcon from "../images/web-na-mieru/web-na-mieru-integracia.svg";

//import zonesColage from "../images/zakaznicka-zona/okna.jpg";

export const pageQuery = graphql`
  query {
    articles: wpCategory(slug: { eq: "webdizajn-web-a-portaly" }) {
      posts {
        nodes {
          title
          excerpt
          slug
          featuredImage {
            node {
              altText
              title
              gatsbyImage(width: 1920)
              sourceUrl
            }
          }
        }
      }
    }
    mysqlProjects(url: { eq: "plusim" }) {
      url
      real_url
      client
      hashtag
      project_description
      case_study_title
      case_study_rok
      case_study_sluzby
      case_study_vyzva
      case_study_proces
      case_study_riesenie
      ogDescription
    }
    files: allFile(
      filter: {
        relativeDirectory: { eq: "plusim" }
        sourceInstanceName: { eq: "images-projects" }
      }
    ) {
      nodes {
        name
        publicURL
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    projekty: allMysqlProjects(sort: { fields: case_study_rok, order: DESC }) {
      nodes {
        url
        client
        case_study_description
      }
    }
    logosWhite: allFile(
      filter: {
        name: { eq: "logo-white" }
        sourceInstanceName: { eq: "images-projects" }
      }
    ) {
      nodes {
        name
        relativeDirectory
        publicURL
      }
    }
  }
`;

const WebNaMieru = (pageQuery) => {
  const images = {};
  pageQuery.data.files.nodes.map((node) => {
    let index = node.name;
    images[index] = node;
  });

  return (
    <Layout pageName="programovanie-na-mieru-main">
      <SEO
        title="Web na mieru"
        description="Web na mieru je termín, ktorý sa v slovenčine používa na označenie webových stránok alebo aplikácií, ktoré sú navrhnuté a vyvinuté špeciálne pre konkrétneho klienta alebo účel. Takýto web nie je vytvorený pomocou predpripravených šablón alebo platformy, ale je navrhnutý tak, aby presne zodpovedal potrebám a požiadavkám zákazníka."
      />
      <MainSectionZakaznickaZona
        heading="Web na mieru"
        id="sm"
        textHtml={
          <>
            Unikátne riešenie pre vaše jedinečné potreby. Vytvoríme Vám
            digitálnu prítomnosť presne podľa vašich predstáv.
          </>
        }
      />
      <section id="what-is-client-zone">
        <ProgramovanieSolutionBox
          img={5}
          text={
            <>
              <b>„Web na mieru“</b> je termín, ktorý sa v slovenčine používa na
              označenie
              <b> webových stránok</b> alebo <b>aplikácií</b>, ktoré sú
              navrhnuté a vyvinuté špeciálne pre <b>konkrétneho klienta</b>{" "}
              alebo <b>účel</b>. Takýto web nie je vytvorený pomocou
              predpripravených šablón alebo platformy, ale je navrhnutý tak, aby
              presne zodpovedal <b>potrebám</b> a <b>požiadavkám zákazníka</b>.
            </>
          }
        />
      </section>

      {/*<section id="client-zone-colage">
        <img
          className="img"
          src={zonesColage}
          alt="Zákaznícke zóny"
          
        />
      </section>*/}

      <section className="section-blog">
        <div className="banner-blog">
          <h2>
            Dôvody a situácie, kedy je programovanie Webstránky na mieru
            najvhodnejšie ?
          </h2>
        </div>
      </section>
      <section id="case-study-info">
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={jedinecnostIcon}
                alt="Web na mieru: unikátnost, jedinečnosť"
                title="Web na mieru: unikátnost, jedinečnosť"
              />
            </div>
          </div>
          <div>
            <h3>
              Jedinečnosť{" "}
              <StaticImage
                className="img"
                src="../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Stránka je navrhnutá tak, aby presne vyhovovala značke, hodnotám a
              vizuálnemu štýlu konkrétnej spoločnosti.
            </p>
          </div>
        </div>
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={flexibilitaIcon}
                alt="Web na mieru: flexibilita"
                title="Web na mieru: flexibilita"
              />
            </div>
          </div>
          <div>
            <h3>
              Flexibilita{" "}
              <StaticImage
                className="img"
                src="../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Možnosť prispôsobiť web tak, aby vyhovoval špecifickým potrebám a
              funkcionalitám.
            </p>
          </div>
        </div>
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={rastIcon}
                alt="Web na mieru: Rast a rozšírenie"
                title="Web na mieru: Rast a rozšírenie"
              />
            </div>
          </div>
          <div>
            <h3>
              Rast a rozšírenie{" "}
              <StaticImage
                className="img"
                src="../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Možnosť pridávať nové funkcie a moduly podľa rastu a potrieb
              firmy.
            </p>
          </div>
        </div>
        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={optimalizaciaIcon}
                alt="Web na mieru: optimalizácia pre vyhľadávače"
                title="Web na mieru: optimalizácia pre vyhľadávače"
              />
            </div>
          </div>
          <div>
            <h3>
              Optimalizácia pre vyhľadávače{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Možnosť optimalizovať web presne podľa potrieb SEO, čo môže viesť
              k vyššej viditeľnosti v internetových vyhľadávačoch.
            </p>
          </div>
        </div>

        <div class="vyzva">
          <div>
            <div class="iconContainer">
              <img
                className="img"
                src={integraciaIcon}
                alt="Web na mieru: integracia s inými systémami"
                title="Web na mieru: integracia s inými systémami"
              />
            </div>
          </div>
          <div>
            <h3>
              Integrácia s inými systémami{" "}
              <StaticImage
                className="img"
                src="../../images/new-web/arrow.svg"
                alt="Šípka vpravo"
                placeholder="blurred"
              />
            </h3>
            <p>
              Možnosť integrovať web so špecifickými externými nástrojmi alebo
              aplikáciami.
            </p>
          </div>
        </div>
      </section>
      <section className="section-blog">
        <div className="banner-blog">
          <p>
            Proces výroby webu na mieru zahrňuje kroky pri navrhovaní a vývoji
            webovej stránky. Je kľúčový pre vytvorenie jednoduchého, funkčného a
            užívateľsky prívetivého webu.
          </p>
        </div>
      </section>
      <section id="online-business-solutions">
        <h2>Ako budeme postupovať a ako prebieha náš proces?</h2>
        <div className="solution-box loaded">
          <img
            className="img solution-img"
            src={objavenieImg}
            alt="Web na mieru: konzultácia a objavenie"
            title="Web na mieru: konzultácia a objavenie"
          />
          <div className="solution-description">
            <h3>Objavenie vašich snov</h3>
            <p>
              Počas našej prvej konzultácie sa spoločne zameriame na pochopenie
              vašej vízie a čo od webu skutočne očakávate. Predstavte si tento
              krok ako prvé rande, kde sa obidve strany spoznávajú.
            </p>
            {/*<br />
            <p className="bold">
              Prvé stretnutie je na nás a trvá 1 – 2 hodiny
            </p>*/}
          </div>
        </div>

        <div className="solution-box loaded">
          <div className="solution-description">
            <h3>Kreslenie vašich predstáv</h3>
            <p>
              Na základe vašich potrieb a želaní vytvoríme unikátny dizajn,
              ktorý oživí vaše ideály. Je to akoby sme pre vás napísali osobnú
              melódiu, ktorá rezonuje s vaším vnútorným hlasom.
            </p>
          </div>
          <img
            className="img solution-img"
            src={dizajnImg}
            alt="Web na mieru: dizajn a kreslenie"
            title="Web na mieru: dizajn a kreslenie"
          />
        </div>

        <div className="solution-box loaded">
          <img
            className="img solution-img"
            src={realizaciaImg}
            alt="Web na mieru: realizácia"
            title="Web na mieru: realizácia"
          />
          <div className="solution-description">
            <h3>Magická realizácia</h3>
            <p>
              Po schválení dizajnu sa pustíme do vývoja. Každá funkcia, každý
              detail bude vytvorený na mieru, akoby ste mali vlastného krajčíra
              pre vašu stránku.
            </p>
          </div>
        </div>

        <div className="solution-box loaded">
          <div className="solution-description">
            <h3>Pozorovanie v akcii</h3>
            <p>
              Pred tým, než vám predáme kľúče od vášho nového digitálneho
              domova, dôkladne testujeme všetko, aby sme sa uistili, že všetko
              funguje hladko. Je to ako jazda v novom aute - chceme, aby ste sa
              cítili bezpečne a pohodlne.
            </p>
          </div>
          <img
            className="img solution-img"
            src={testovanieImg}
            alt="Web na mieru: testovanie"
            title="Web na mieru: testovanie"
          />
        </div>

        <div className="solution-box loaded">
          <img
            className="img solution-img"
            src={nasadenieImg}
            alt="Web na mieru: spustenie a podpora"
            title="Web na mieru: spustenie a podpora"
          />
          <div className="solution-description">
            <h3>Oslava spustenia a kontinuálna podpora</h3>
            <p>
              Po úspešnom spustení vášho webu sa s vami tešíme z vášho
              digitálneho úspechu. Naša zodpovednosť však nekončí. Ponúkame vám
              kontinuálnu <b>podporu, servis a monitoring</b>, aby váš web
              neustále prosperoval a vyhovoval vašim podnikateľským potrebám.
            </p>
          </div>
        </div>
      </section>

      <References />

      <ZonaClankySlider clanky={pageQuery.data.articles} />

      <section id="zonaFaq">
        <h2>
          Často kladené otázky
          <StaticImage
            className="img"
            src="../../images/new-web/arrow.svg"
            alt="Šípka vpravo"
            placeholder="blurred"
          />
        </h2>

        <p>
          Pri vývoji webu na mieru sa klienti často pýtajú na mnohé aspekty
          procesu. Tu je zoznam niektorých z najčastejších otázok a odpovedí:
        </p>
        <br />
        <br />

        <ZonaFaqQuestion
          question="Koľko bude stáť vývoj webu na mieru?"
          answer={
            <>
              Cena závisí od komplexnosti, funkcií a dizajnu, ktoré potrebujete.
              Po detailnej konzultácii vám poskytneme presnú cenovú ponuku.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Ako dlho trvá vytvorenie webu na mieru?"
          answer={
            <>
              Časový rámec závisí od zložitosti projektu. Typický web môže trvať
              od niekoľkých týždňov po niekoľko mesiacov.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Budem môcť web aktualizovať sám/sama?"
          answer={
            <>
              Áno, poskytneme vám prístup a zaškolenie, ako spravovať obsah a
              základné funkcie vášho webu.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Je moje stránka optimalizovaná pre mobilné zariadenia?"
          answer={
            <>
              Áno, všetky naše weby sú responzívne a sú optimalizované pre
              mobilné zariadenia.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Budete poskytovať hosting a doménové služby?"
          answer={
            <>
              Môžeme vám poskytnúť odporúčania a pomoc s hostingom a doménou
              alebo využiť vaše existujúce služby.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Aké technológie používate pri vývoji?"
          answer={
            <>
              Používame rôzne technológie v závislosti od potrieb projektu,
              vrátane moderných CMS systémov, frameworkov a jazykov.
            </>
          }
        />

        <ZonaFaqQuestion
          question="Ako bude prebiehať komunikácia počas vývoja?"
          answer={
            <>
              Budeme pravidelne komunikovať prostredníctvom emailov, telefonátov
              a stretnutí, aby sme vás informovali o pokroku a získali vašu
              spätnú väzbu.
            </>
          }
        />
      </section>
      <ContactUs
        text={<>Máte záujem o web na mieru?</>}
        button="Napíšte nám e-mail"
      />
    </Layout>
  );
};

export default WebNaMieru;
